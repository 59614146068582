import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';

interface SidebarContextProps {
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
  isTopbarOpen: boolean;
  isCommentOpen: boolean;
  toggleTopbar: () => void;
  toggleFullScreen: () => void;
  toggleComment: () => void;
}

const SidebarContext = createContext<SidebarContextProps | undefined>(undefined);

export const SidebarProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(() => {
    const savedState = localStorage.getItem('isSidebarOpen');
    return savedState ? JSON.parse(savedState) : true;
  });

  const [isTopbarOpen, setIsTopbarOpen] = useState<boolean>(() => {
    const savedState = localStorage.getItem('isTopbarOpen');
    return savedState ? JSON.parse(savedState) : true; // Default to open
  });

  const [isCommentOpen, setIsCommentOpen] = useState<boolean>(() => {
    const savedState = localStorage.getItem('isCommentOpen');
    return savedState ? JSON.parse(savedState) : true;
  });


  const toggleSidebar = () => {
    setIsSidebarOpen(prev => !prev);
  };

  const toggleTopbar = () => {
    setIsTopbarOpen(prev => !prev);
  };

  const toggleComment = () => {
    setIsCommentOpen(prev => !prev);
  };

  // New function to toggle both Sidebar and UI visibility together
  const toggleFullScreen = () => {
    const shouldOpen = !(isSidebarOpen || isTopbarOpen);
    setIsSidebarOpen(shouldOpen);
    setIsTopbarOpen(shouldOpen);
  };

  useEffect(() => {
    localStorage.setItem('isSidebarOpen', JSON.stringify(isSidebarOpen));
  }, [isSidebarOpen]);

  useEffect(() => {
    localStorage.setItem('isTopbarOpen', JSON.stringify(isTopbarOpen));
  }, [isTopbarOpen]);

  useEffect(() => {
    localStorage.setItem('isCommentOpen', JSON.stringify(isCommentOpen));
  }, [isCommentOpen]);

  return (
    <SidebarContext.Provider value={{ isSidebarOpen, toggleSidebar, isTopbarOpen, isCommentOpen, toggleTopbar, toggleFullScreen, toggleComment }}>
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebar = (): SidebarContextProps => {
  const context = useContext(SidebarContext);
  if (!context) {
    throw new Error('useSidebar must be used within a SidebarProvider');
  }
  return context;
};
