import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import Layout from './components/Layout';
import DocumentForm from './components/DocumentForm';
import Playground from './components/Playground';
import InitialRedirect from './components/InitialRedirect';
import './App.css';

const App: React.FC = () => {
  const { keycloak, initialized } = useKeycloak();

  if (!initialized) {
    return <div>Loading...</div>;
  }

  if (!keycloak.authenticated) {
    keycloak.login();
    return null;
  }

  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<InitialRedirect />} />
          <Route path="/documents" element={<DocumentForm />} />
          <Route path="/playground" element={<Playground />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Layout>
    </Router>
  );
};

export default App;