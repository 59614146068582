import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const InitialRedirect: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const submission = localStorage.getItem('submission');
    const page = localStorage.getItem('page');
    const submission1_data = localStorage.getItem('submission1_excalidrawData_0');

    if (submission) {
      if(submission === 'submission1' && !submission1_data){
        // Redirect to /documents
        navigate('/documents');
      } else {
        // Redirect to /playground with state containing submissionKey and page
        navigate('/playground', { state: { submissionKey: submission, page } });
      }
    } else {
      // Redirect to /documents
      navigate('/documents');
    }
  }, [navigate]);

  return null;
};

export default InitialRedirect;