import React from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { useSidebar } from '../context/SidebarContext';


interface HeaderProps {
  title: string;
  icon: string;
}

const Header: React.FC<HeaderProps> = ({ title, icon }) => {

  const { keycloak } = useKeycloak();
  const { isTopbarOpen } = useSidebar();

  const handleLogout = () => {
    keycloak.logout();
  };

  if (!isTopbarOpen) return null; // Do not render if UI is hidden

  return (
    <header className="flex gap-5 justify-between px-4 py-2 font-semibold bg-emerald-700 max-md:flex-wrap max-md:pr-5">
      <div className="flex gap-4 text-lg leading-5 text-white">
        <img loading="lazy" src={icon} alt={`${title} icon`} className="shrink-0 aspect-square w-[37px]" />
        <div className="flex gap-2 my-auto">
          <div className="my-auto">{title}</div>
          <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/a8a85568df47ee815c559c1b9afb30385c5f609d54e5f16c2b6717f9704b203b?apiKey=0bfc8456e31a4c47a3987f9a6c2ca142&" alt="" className="shrink-0 w-6 aspect-square" />
        </div>
      </div>
      <nav className="flex gap-4 items-center self-start text-base text-center text-emerald-700 whitespace-nowrap">
        <button aria-label="Notifications" className="shrink-0 self-stretch my-auto aspect-square w-[26px]">
          <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/2915ce5e74773074800e9dc854d49e6a4b3de4c6df49534cd1092a8f5b2f081d?apiKey=0bfc8456e31a4c47a3987f9a6c2ca142&" alt="" className="w-full h-full" />
        </button>
        <button className="flex gap-2.5 self-stretch px-3.5 py-1.5 bg-white rounded-md">
          <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/29575791ee1d013e74a347f9a3b2108e8151c623a5145cc3da6dc4e90b332af8?apiKey=0bfc8456e31a4c47a3987f9a6c2ca142&" alt="" className="shrink-0 my-auto w-4 aspect-square" />
          <span>Publish</span>
        </button>
        <button aria-label="Messages" className="shrink-0 self-stretch my-auto aspect-square w-[26px]">
          <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/01a506cb752299c987050d4818500ded52aab24942f9d95b5098693c318e9dbc?apiKey=0bfc8456e31a4c47a3987f9a6c2ca142&" alt="" className="w-full h-full" />
        </button>
        <button aria-label="User profile" className="shrink-0 self-stretch my-auto w-8 rounded-full border border-gray-400 border-solid aspect-square" onClick={handleLogout}>
          <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/bf0599251dbea7a0075960459828731575262281a893c0970371aade6416bfe8?apiKey=0bfc8456e31a4c47a3987f9a6c2ca142&" alt="User avatar" className="w-full h-full rounded-full" />
        </button>
      </nav>
    </header>
  );
};

export default Header;