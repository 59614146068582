import React, { useState } from 'react';
import Header from './Header';
import StageSelector from './StageSelector';
import TeamPartnerSelector from './TeamPartnerSelector';
import ProcessStages from './ProcessStages';
import SubmissionBar from './SubmissionBar';
import Sidebar from './Sidebar';
import MainContent from './MainContent';
import { SidebarProvider } from '../context/SidebarContext';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <SidebarProvider>
      <div className="flex flex-col h-screen">
        <Header 
          title="Singapore MRT" 
          icon="https://cdn.builder.io/api/v1/image/assets/TEMP/734a54e4462c1e87a079c3eca9f8f4e99d7cfc55b41802539ddd36cd1457ea7c?apiKey=0bfc8456e31a4c47a3987f9a6c2ca142&" 
        />
        <StageSelector currentStage="Stage 1" />
        <TeamPartnerSelector />
        <ProcessStages />
        <SubmissionBar />
        <div className="flex flex-1 max-md:flex-col">
          <Sidebar />
          <MainContent>
            {children}
          </MainContent>
        </div>
      </div>
    </SidebarProvider>
  );
};

export default Layout;