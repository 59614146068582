import React from 'react';
import { useSidebar } from '../context/SidebarContext';

interface MainContentProps {
  children: React.ReactNode;
}

const MainContent: React.FC<MainContentProps> = ({ children }) => {
  const { isSidebarOpen } = useSidebar();

  return (
    <main
      className={`flex flex-col transition-all duration-300 ${
        isSidebarOpen ? 'w-10/12' : 'w-full'
      } max-md:ml-0 max-md:w-full`}
    >
      {children}
    </main>
  );
};

export default MainContent;
